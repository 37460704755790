<template>
  <base-modal :show="show" maxWidth="38rem" @close="handleClose">
    <div class="body">
      <h3>Editar Biografia</h3>
      <div class="dates-wrapper">
        <div class="input-wrapper">
          <label for="" class="input-label">Data do Aniversário</label>
          <input v-model="birthday_date" type="date" class="date-input" />
        </div>
        <div class="input-wrapper">
          <label for="" class="input-label">Data de Entrada</label>
          <input v-model="entry_date" type="date" class="date-input" />
        </div>
      </div>
      <textarea name="" id="" cols="30" rows="10" v-model="bio"></textarea>
      <div class="btn-wrapper">
        <button class="main-btn secondary-btn" @click="handleClose">
          Cancelar
        </button>
        <button class="main-btn" @click="saveProfile">Salvar</button>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/BaseModal";

export default {
  name: "EditProfileModal",
  components: { BaseModal },
  data() {
    return {
      birthday_date: this.profile.birthday,
      entry_date: this.profile.entry,
      bio: this.profile.bio
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    saveProfile() {
      this.$emit("save-profile", {
        birthday_date: this.birthday_date,
        entry_date: this.entry_date,
        bio: this.bio
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.body {
  padding: 0.5rem 0.5rem;
  text-align: center;
  font-size: 1.25rem;
}

h3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  text-align: center;
  text-transform: uppercase;
  color: #403C56;
  margin-bottom: 0.5em;
}

.dates-wrapper {
  display: flex;
  gap: 0.5em;
  width: 100%;
}
.input-wrapper {
  text-align: left;
  width: 100%;
}
.input-label {
  font-weight: 500;
  font-size: 1.1em;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: #403c56;
}

.secondary-btn {
  background: #7c7884;
}

.secondary-btn:hover {
  background: #b9b9b9;
}

.date-input {
  width: 100%;
  height: 3.5rem;
  padding: 0.25em 0.5em;

  background: #8f81b2;
  box-shadow: 2px 2px 0px #000000;

  color: #fff;
  font-size: 1.4rem;
}

textarea {
  background: #8f81b2;
  box-shadow: 2px 2px 0px #000000;
  margin: 1em 0;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  padding: 0.5em;
  border: 0;
  resize: vertical;
}
/* Arrow */
// .main-select::after {
//   content: "";
//   clip-path: polygon(100% 0%, 0 0%, 50% 100%);
//   background-color: #fff;

//   width: 0.8rem;
//   height: 0.8rem;
//   justify-self: end;
// }
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  // display: none;
  // -webkit-appearance: none;
  cursor: pointer;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23ffffff" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.btn-wrapper {
  display: flex;
  gap: 0.5em;
}
</style>