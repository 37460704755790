<template>
  <section class="avatar-store">
    <h1 class="profile-title">Loja do Avatar</h1>
    <div class="avatar-store__content profile-card">
      <div class="avatar-store__content__header">
        <button
          v-for="(tab, i) in storeTabs"
          :class="{ 'active-store-link': tab.value == activeTab.value }"
          @click.prevent="activeTab = tab"
          :key="i"
        >
          {{ tab.display }}
        </button>
      </div>
      <div class="avatar-store__content__content">
        <AvatarProduct
          v-for="(p, i) in products"
          :key="i"
          :product="p"
          :active="gearItemIsEquiped(p)"
          @buyProduct="selectedProduct = p"
          @equip-gear-item="$emit('equip-gear-item', $event, activeTab)"
        />
      </div>
    </div>

    <StoreConfirmationDialog
      v-if="selectedProduct != null"
      :show="selectedProduct != null"
      :product="selectedProduct"
      @close="handleClose"
      @buy-avatar-product="$emit('buy-avatar-product', $event)"
    />
  </section>
</template>

<script>
import StoreConfirmationDialog from "../StoreConfirmationDialog.vue";
import AvatarProduct from "./AvatarProduct.vue";

export default {
  components: { AvatarProduct, StoreConfirmationDialog },
  props: {
    gearItems: { type: Array, required: true },
    userGear: { type: Object, required: false },
  },
  data() {
    return {
      storeTabs: [
        { value: "HEAD", display: "Cabeça" },
        { value: "TORSO", display: "Tronco" },
        { value: "LEGS", display: "Pernas" },
      ],
      activeTab: null,
      selectedProduct: null,
    };
  },
  created() {
    this.activeTab = this.storeTabs[0];
  },
  methods: {
    handleClose() {
      this.selectedProduct = null;
    },
    clearSelectedProduct() {
      this.selectedProduct = null;
    },
    gearItemIsEquiped(product) {
      let ids = [];
      if (this.userGear.head != null) ids.push(this.userGear.head.gear_item_id);
      if (this.userGear.torso != null) ids.push(this.userGear.torso.gear_item_id);
      if (this.userGear.legs != null) ids.push(this.userGear.legs.gear_item_id);

      return ids.includes(product.id);
    }
  },
  computed: {
    products() {
      return this.gearItems.filter((p) => p.body_part == this.activeTab.value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";

.avatar-store {
  &__content {
    &__header {
      display: flex;
      justify-content: center;

      padding: 1rem;

      & button {
        color: white;
        background-color: transparent;
        font-weight: 700;
        font-size: 1em;
        position: relative;
        padding: 0.3rem 1rem;
        border-bottom: 0.3rem solid transparent;
      }

      & button.active-store-link {
        color: $main-color;
        border-bottom: 0.3rem solid $main-color;
      }
    }

    &__content {
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: center;
      width: 100%;
      gap: 1rem;
      padding: 1rem;

      & > div {
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        height: 100%;
        font-size: 0.5rem;
      }
    }
  }
}
</style>