const httpClient = require("@/services/helpers/http.client").default;
const baseApiUrl = process.env.VUE_APP_API_BASE_URL;

import Product from "@/entities/Product";
const model = Product;

const parseAquiredProduct = (apiAquiredProduct) => {
    const parsedAquiredProduct = {
        id: apiAquiredProduct.product_id,
        name: apiAquiredProduct.product_name,
        description: '',
        price: apiAquiredProduct.points,
        image: apiAquiredProduct.product_image,
        purchase_status: apiAquiredProduct.status ?? null,
    };
    return new model(parsedAquiredProduct);
};

export default class AvatarService {
    static getGearItemsList() {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/gear/items/list`)
                .then((response) => {
                    let products = response.data;
                    resolve(products);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static equipUser(gear_item_head_id, gear_item_torso_id, gear_item_legs_id, defaultAvatar) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/gear/items/equipuser`, {
                gear_item_head_id: gear_item_head_id,
                gear_item_torso_id: gear_item_torso_id,
                gear_item_legs_id: gear_item_legs_id,
                default_avatar: defaultAvatar,
            })
                .then((response) => {
                    let product = response.data;
                    resolve(product);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static purchaseGearItem(id) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/gear/items/purchase`, { gear_item_id: id })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }


    static saveDefaultAvatar(attributes) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/set-default-avatar`, { attributes })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }
}