<template>
  <button :class="{ 'acquired': acquired, 'expensive': isExpensive && !acquired, 'disabled': disabled }"
    :disabled="disabled">
    <span>
      <slot />
    </span>
    <div class="price-tag">
      <img src="../../assets/images/store/currency_icon.svg" alt="">
      <span>{{ parseInt(price) }}</span>
    </div>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    price: { type: Number, required: true },
    acquired: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: true },
    isExpensive: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";

button {
  padding: 0 0.4rem 0.4rem 0.4rem;
  background: $purple-darker;
  font-size: 1.1rem;
  width: 100%;

  &>span {
    font-weight: 600;
    font-size: 0.8em;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  & .price-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $purple;
    gap: 0.3rem;

    &>img {
      width: 1.5rem;
    }

    &>span {
      font-weight: 700;
      font-size: 1.2em;
      color: #ffffff;
    }
  }
}

.acquired {
  background: $main-color-darker;

  &>.price-tag {
    background: $main-color;
  }
}

.expensive {
  background: #645f74;

  &>.price-tag {
    background: #837f90;
  }
}

.disabled {
  cursor: not-allowed;
}
</style>