<template>
  <div v-if="gear" class="avatar">
    <img class="avatar__full" :src="`${this.backOfficeUrl}/storage/users/${gear}`" alt="avatar full" />
  </div>
</template>

<script>
export default {
  props: {
    gear: { type: String, default: null },
  },
  data() {
    return {
      backOfficeUrl: process.env.VUE_APP_BO_BASE_URL,
    };
  },
};
</script>

<style scoped lang="scss">
.avatar {
  height: 100%;
  display: flex;
  justify-content: center;

  &__full {
    position: relative;
    height: 100%;
  }
}
</style>
