<template>
  <div class="avatar-product">
    <div :class="{ active: active, owned: product.owned }">
      <div class="outer">
        <div class="inner">
          <span class="avatar-product-active" v-if="active" />
          <img
            :style="handleImageSize()"
            :src="getAvatarItemImageUrl(product)"
            alt="product image"
          />
        </div>
      </div>
    </div>
    <p>{{ product.name }}</p>

    <buy-button
      :price="product.baseline_cost"
      :acquired="Boolean(product.owned)"
      :isExpensive="!product.can_purchase"
      @click.native="Boolean(product.owned) ? $emit('equip-gear-item', product) : $emit('buyProduct', product)"
      :disabled="!product.can_purchase"
    >
      {{ buttonText }}
    </buy-button>
  </div>
</template>

<script>
import BuyButton from "../Utils/BuyButton.vue";

export default {
  components: { BuyButton },
  props: {
    product: Object,
    active: { type: Boolean, default: false }
  },
  data() {
    return {
      backOfficeUrl: process.env.VUE_APP_BO_BASE_URL,
    };
  },
  methods: {
    getAvatarItemImageUrl(product) {
      // TODO Move this logic to backend so that the URL already comes assembled from the API.
      let part_folder = "head";
      if (product.body_part == "TORSO") part_folder = "body";
      else if (product.body_part == "LEGS") part_folder = "legs";

      return this.backOfficeUrl + "/storage/avatar/" + part_folder + "/" + product.image_url;
    },
    handleImageSize() {
      if (this.product.body_part === "HEAD") return "width: 20rem; margin-left: -3rem; max-width: unset";
      if (this.product.body_part === "TORSO") return "width: 7rem";
      if (this.product.body_part === "LEGS") return "width: 6rem";
    }
  },
  computed: {
    buttonText() {
      if (this.product.owned) return "Equipar";
      if (!this.product.can_purchase) return "Sem créditos";
      return "Comprar";
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";

$width: 12rem;
$activeBorderWidth: 0.1rem;
$imageBackgroundColor: #362a52;

.avatar-product {
  width: $width;
  justify-content: center;

  .owned {
    cursor: not-allowed;
  }

  & > p {
    color: white;
    font-size: 1.2rem;
    margin: 0.6rem 0;
    text-transform: uppercase;
    font-weight: 400;
  }

  & > div {
    position: relative;
    width: $width;
    height: calc($width - 2.5rem);
    background: $imageBackgroundColor;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.active {
  & .outer,
  & .inner {
    clip-path: polygon(
      10% 0%,
      90% 0%,
      100% 10%,
      100% 90%,
      90% 100%,
      10% 100%,
      0% 90%,
      0% 10%
    );
  }

  & .outer {
    background-color: $main-color;
    height: 100%;
    width: 100%;
  }

  // https://stackoverflow.com/questions/31854185/how-to-add-border-in-my-clip-path-polygon-css-style
  & .inner {
    background-color: $imageBackgroundColor;
    position: absolute;
    top: $activeBorderWidth;
    left: $activeBorderWidth;
    width: calc(100% - $activeBorderWidth * 2);
    height: calc(100% - $activeBorderWidth * 2);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .avatar-product-active {
    width: 1rem;
    height: 1rem;
    background-color: $main-color;
    position: absolute;
    border-radius: 100%;
    top: 0.5rem;
    left: 0.5rem;
  }
}
</style>