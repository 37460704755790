export default class Product {
    constructor({
        id,
        name,
        price,
        description,
        image,
        purchase_status,
        status_date,
    }) {
        this.id = id;
        this.name = name;
        this.price = price;
        this.description = description;
        this.image = image;
        this.purchase_status = purchase_status;
        this.status_date = status_date;
    }

    getStatusText() {
        if (this.purchase_status == 'RECEIVED') {
            return 'Pendente'
        } else if (this.purchase_status == 'ACEPTED') {
            return 'Aceite'
        } else if (this.purchase_status == 'DELIVERED') {
            return 'Entregue'
        } else if (this.purchase_status == 'REJECTED') {
            return 'Rejeitada'
        }

        return this.purchase_status;
    }
}