<template>
  <div class="profile-wrapper">
    <Info :user="userInfo" />
    <div class="profile-avatar">
      <Avatar :gear="getUserEquippedGear.full_profile_picture" />
    </div>
    <AvatarStore
      :gearItems="gearItems"
      :userGear="getUserEquippedGear"
      @buy-avatar-product="buyAvatarProduct($event)"
      @equip-gear-item="equipGearProduct($event)"
      ref="avatarStore"
    />
  </div>
</template>

<script>
import Avatar from "../../components/Profile/Avatar.vue";
import AvatarStore from "../../components/Profile/AvatarStore.vue";
import Info from "../../components/Profile/Info.vue";
import AvatarService from "@/services/avatar.service.js";

import { mapGetters } from "vuex";

export default {
  components: { Info, AvatarStore, Avatar },
  data() {
    return {
      gearItems: [],
    };
  },
  computed: {
    ...mapGetters("user", ["authUser", "userProfile", "getUserEquippedGear", "getDefaultAvatar"]),
    userInfo() {
      return {
        user: this.authUser,
        profile: this.userProfile,
      };
    },
  },
  methods: {
    fetchGearItems() {
      AvatarService.getGearItemsList()
        .then((response) => {
          this.gearItems = response.gear_items;

          this.$store.dispatch(
            "user/updateUserEquippedGear",
            response.user_equipped_gear
          );
        })
        .catch((error) => {
          this.$noty.error(error.message);
        });
    },
    equipGearProduct(product) {
      AvatarService.equipUser(
        product.body_part == "HEAD" ? product.id : (this.getUserEquippedGear.head ? this.getUserEquippedGear.head.gear_item_id : null),
        product.body_part == "TORSO" ? product.id : (this.getUserEquippedGear.torso ? this.getUserEquippedGear.torso.gear_item_id : null),
        product.body_part == "LEGS" ? product.id : (this.getUserEquippedGear.legs ? this.getUserEquippedGear.legs.gear_item_id : null),
        this.getDefaultAvatar
      )
        .then((response) => {
          
          // Guardar imagem
          // response.profile_picture

          // // Update gear item list
          this.$store.dispatch(
            "user/updateUserEquippedGear",
            response.user_equipped_gear
          );

          this.$noty.success("Produto equipado!");
        })
        .catch((error) => {
          this.$noty.error(error.message);
        });

    },
    buyAvatarProduct(product) {
      this.purchaseGearItem(product.id);
    },
    purchaseGearItem(product_id) {
      AvatarService.purchaseGearItem(product_id)
        .then((response) => {
          this.$refs.avatarStore.clearSelectedProduct();

          // Update user points
          this.$store.dispatch(
            "user/updateUserPoints",
            response.points_after_purchase
          );

          // Update gear item list
          this.gearItems = response.gear_items;
          this.$store.dispatch(
            "user/updateUserEquippedGear",
            response.user_equipped_gear
          );

          this.$noty.success("Produto comprado, agora já o podes equipar!");
        })
        .catch((error) => {
          this.$noty.error(error.message);
        });
    },
  },
  created() {
    this.fetchGearItems();
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";

$card-bg-color: #221b38;

.profile-wrapper {
  padding: 2rem 4rem;
  display: flex;
  gap: 2rem;
  font-size: 1.5rem;
  justify-content: center;

  .profile-avatar {
    position: relative;
    display: flex;
    align-items: center;
    left: -5rem;
    margin-top: 6rem;
    height: 37.5rem;
    min-width: 16rem;
  }

  &::v-deep .profile-card {
    background-color: #221b38;
    box-shadow: 0.3rem 0.3rem 0 $purple;
  }

  &::v-deep .profile-title {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 2px 2px 0px black;
    margin-bottom: 2rem;
    color: white;
  }
}
</style>
