<template>
  <base-modal :show="show" @close="handleClose" :showCloseButton="false" :maxWidth="'fit-content'" class="modal">
    <h1>Mensagem Confirmação</h1>
    <div class="modal__content">
      <div class="item">
        <div class="item__image">
          <img :src="getAvatarItemImageUrl(product)" alt="">
        </div>
        <div class="item__details">
          <p class="item__details__name">{{ product.name }}</p>
          <div class="item__details__stock">
            <figure class="item__details__stock__hex" />
            <span>Em Stock</span>
          </div>
          <p class="item__details__price">Custo &nbsp; <em class="currency">{{ product.baseline_cost }}</em></p>
        </div>
      </div>
      <div class="item">
        <div class="item__user-avatar"
          :style="`background-image: url(${'https://ui-avatars.com/api/?name=' + userLetters})`" />
        <div class="item__details">
          <p class="item__details__name">{{ authUser.name }}</p>
          <p class="item__details__price">Restantes &nbsp; <em class="currency">{{ userProfile.points_sum - product.baseline_cost }}</em></p>
        </div>
      </div>
    </div>
    <div class="modal__actions">
      <button class="main-btn cancel-button button-font" @click="handleClose">Cancelar</button>
      <button class="main-btn button-font" @click="$emit('buy-avatar-product', product)">Comprar</button>
    </div>
  </base-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import StringManipulator from "@/tools/StringManipulator";
import BaseModal from "./BaseModal.vue";


export default {
  components: { BaseModal },
  props: {
    show: { type: Boolean, default: false },
    product: { type: Object, default: {} },
  },
  data() {
    return {
      backOfficeUrl: process.env.VUE_APP_BO_BASE_URL,
    }
  },
  computed: {
    ...mapGetters('user', ['authUser', 'userProfile']),
    userLetters() {
      return StringManipulator.getNameFirstAndLastLetters(this.authUser.name)
    }
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    getAvatarItemImageUrl(product) {
      // TODO Move this logic to backend so that the URL already comes assembled from the API.
      let part_folder = "head";
      if (product.body_part == "TORSO") part_folder = "body";
      else if (product.body_part == "LEGS") part_folder = "legs";

      return this.backOfficeUrl + "/storage/avatar/" + part_folder + "/" + product.image_url;
    }
  },
}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

$gap: 4.5rem;

.modal {
  display: flex;
  flex-direction: column;

  & h1 {
    color: #403C56;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: $gap;
  }

  &__content {
    display: flex;
    gap: 1.5rem;
    margin-bottom: $gap;

    & .item {
      display: flex;
      background-color: white;
      border-radius: 8px;
      width: 16rem;
      gap: 1rem;
      padding: 0.6rem;

      &__image,
      &__user-avatar {
        width: 4.3rem;
        height: 4.3rem;
        border-radius: 1000px;
        flex-shrink: 0;
      }

      &__image {
        background-color: #362a52;
        display: flex;
        justify-content: center;
        align-items: center;

        &>img {
          width: 50%;
        }
      }

      &__user-avatar {
        background-position: center;
        background-size: cover;
      }

      &__details {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;

        &__name {
          font-weight: 500;
          font-size: 1.1rem;
        }

        &__stock {
          text-transform: uppercase;
          color: $gray;
          font-size: 0.7rem;
          font-weight: 700;
          display: flex;
          align-items: center;
          gap: 0.2rem;

          &__hex {
            clip-path: var(--hexagon);
            width: 1rem;
            height: 1rem;
            background-color: $main-color;
          }
        }

        &__price {
          font-weight: 400;
          text-transform: uppercase;
          font-size: 0.7rem;
          color: #221B38;
        }
      }

    }
  }

  &__actions {
    display: flex;
    gap: 1rem;

    & .cancel-button {
      background-color: $gray;
    }
  }

}

.currency {
  color: $main-color;
  font-style: normal;
  font-weight: 700;
}

.button-font {
  font-weight: 600;
}
</style>