<template>
  <section class="profile">
    <h1 class="profile-title">Perfil</h1>
    <div class="profile__info profile-card card-folded">
      <p class="profile__info__name">{{ user.user.name }}</p>
      <div class="profile__info__team">
        <p>{{ user.profile.job }}</p>
        <p>{{ user.profile.current_group }}</p>
      </div>
      <p class="profile__info__description">
        {{ user.profile.bio }}
      </p>
      <div class="profile__info__dates">
        <div class="profile__info__dates__date">
          <p>Data do Aniversário</p>
          <p>
            {{
              user.profile.date_of_birth == null
                ? "--/--/---"
                : user.profile.date_of_birth
            }}
          </p>
        </div>
        <div class="profile__info__dates__date">
          <p>Data de Entrada</p>
          <p>
            {{
              user.profile.date_of_entry_in_company == null
                ? "--/--/---"
                : user.profile.date_of_entry_in_company
            }}
          </p>
        </div>
      </div>

      <button class="main-btn" @click.prevent="showEditModal = true">
        Editar
      </button>
    </div>

    <EditProfileModal @close="closeEditModal" @save-profile="saveProfile($event)" :show="showEditModal" v-if="profileData" :profile="profileData" />
  </section>
</template>

<script>
import EditProfileModal from "@/components/Profile/EditProfileModal";
import UserService from "@/services/user.service";
import { mapGetters } from 'vuex';

export default {
  components: { EditProfileModal },
  data() {
    return {
      showEditModal: false,
      profileData: null
    };
  },
  props: {
    user: { type: Object, required: true },
  },
  mounted() {
    this.profileData = {
      birthday: this.userProfile.date_of_birth,
      entry: this.userProfile.date_of_entry_in_company,
      bio: this.userProfile.bio,
    }
  },
  computed: {
    ...mapGetters('user', ['authUser', 'userProfile']),
  },
  methods: {
    closeEditModal() {
      this.showEditModal = false;
    },
    saveProfile(profile_info) {
      UserService.saveUserProfile({
        id: this.authUser.id,
        bio: profile_info.bio,
        date_of_birth: profile_info.birthday_date,
        date_of_entry_in_company: profile_info.entry_date,
      })
        .then((resp) => {
          let new_profile = this.userProfile;
          new_profile.date_of_birth = profile_info.birthday_date;
          new_profile.date_of_entry_in_company = profile_info.entry_date;
          new_profile.bio = profile_info.bio;
          
          this.$store.dispatch("user/updateUserProfile", new_profile);

          this.$noty.success("O perfil foi atualizado.");
          this.showEditModal = false;
        })
        .catch((err) => {
          this.$noty.error(err.message);
        })
        .finally(() => (this.loading = false));

    },
    convertDateToInput(date) {
      return date;
    },
    convertInputDateToSQL(date) {
      return date;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";

.profile {
  font-weight: bold;
  max-width: 30rem;

  &__info {
    position: relative;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    color: white;

    &__name {
      font-size: 1.5em;
      color: #ff5244;
    }

    &__team > p:last-child {
      color: $purple;
      font-size: 1.2em;
    }

    &__description {
      font-size: 0.8em;
      font-weight: 500;
    }

    &__dates {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      margin-bottom: 1rem;

      &__date > p:last-child {
        color: $purple;
      }
    }

    button {
      padding: 0.7rem 3rem;
      width: fit-content;
      font-weight: 600;
      font-size: 1em;
    }
  }
}
</style>