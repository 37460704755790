<template>
  <transition name="fade">
    <template v-if="show">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container" :style="{'background-color': backgroundColor, 'color': textColor, 'max-width': maxWidth}">
              <div v-if="showCloseButton" class="close-btn" @click="$emit('close')">
                  <img src="@/assets/images/icons/cross_gray.svg" alt="">
              </div>
              <div class="slot-wrapper">
                <slot></slot>
              </div>
          </div>
        </div>
      </div>
    </template>
  </transition>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: "#F5F6FB"
    },
    textColor: {
      type: String,
      default: "#403C56"
    },
    maxWidth: {
        type: String,
        default: "350px"
    }
  },
  methods: {
      open() {
          this.show = true;
      },
      close() {
          this.show = false;
      }
  }
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 100%;
  margin: 0rem auto;
  padding: 1.25rem 1.875rem;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  position: relative;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.close-btn {
    cursor: pointer;
    position: absolute;
    right: 1.125rem;
}
.close-btn img {
    width: 2rem;
}
.slot-wrapper {
    padding-top: 1.6rem;
}
</style>